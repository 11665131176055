// export const validatePropertyForm = (property) => {
//     let isValid = true;
//     const errors = {};

//     // Validate Title
//     if (!property.title.trim()) {
//         isValid = false;
//         errors.title = 'Title is required';
//     }

//     // Validate Description
//     if (!property.description.trim()) {
//         isValid = false;
//         errors.description = 'Description is required';
//     }

//     // Validate Price
//     if (!property.price || property.price <= 0) {
//         isValid = false;
//         errors.price = 'Price must be greater than zero';
//     }

//     // Validate Discount Price
//     if (property.discountPrice && property.discountPrice <= 0) {
//         isValid = false;
//         errors.discountPrice = 'Discount price must be greater than zero';
//     }

//     // Validate Area
//     if (!property.area || property.area <= 0) {
//         isValid = false;
//         errors.area = 'Area must be greater than zero';
//     }

//     // Validate Pincode
//     const pincodeRegex = /^[0-9]{6}$/;
//     if (!pincodeRegex.test(property.pincode)) {
//         isValid = false;
//         errors.pincode = 'Pincode must be a 6 digit number';
//     }

//     // Validate Agent Email
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!emailRegex.test(property.agentEmail)) {
//         isValid = false;
//         errors.agentEmail = 'Enter a valid email address';
//     }

//     // Validate Agent Phone
//     if (!property.agentPhone || property.agentPhone.length !== 10) {
//         isValid = false;
//         errors.agentPhone = 'Agent phone must be 10 digits';
//     }

//     return { isValid, errors };
// };



















import { enforce10DigitNumbersNoSpaces } from '../config/utility';

export const validatePropertyForm = (property) => {
    const errors = {};
    let isValid = true;

    if (!property.title || property.title.trim() === '') {
        errors.title = 'Title is required';
        isValid = false;
    }

    if (!property.description || property.description.trim() === '') {
        errors.description = 'Description is required';
        isValid = false;
    }

    if (property.price === '' || property.price < 0) {
        errors.price = 'Price must be a non-negative number';
        isValid = false;
    }

    if (property.discountPrice === '' || property.discountPrice < 0) {
        errors.discountPrice = 'Discount Price must be a non-negative number';
        isValid = false;
    }

    if (property.floor === '' || property.floor < 0) {
        errors.floor = 'Floor must be a non-negative number';
        isValid = false;
    }

    if (property.area === '' || property.area < 0) {
        errors.area = 'Area must be a non-negative number';
        isValid = false;
    }

    if (!property.city || property.city.trim() === '') {
        errors.city = 'City is required';
        isValid = false;
    }

    if (!property.state || property.state.trim() === '') {
        errors.state = 'State is required';
        isValid = false;
    }

    if (!property.country || property.country.trim() === '') {
        errors.country = 'Country is required';
        isValid = false;
    }

    if (!property.pincode || property.pincode.trim() === '') {
        errors.pincode = 'Pincode is required';
        isValid = false;
    }

    if (property.latitude === '' || property.latitude < 0) {
        errors.latitude = 'Latitude must be a non-negative number';
        isValid = false;
    }

    if (property.longitude === '' || property.longitude < 0) {
        errors.longitude = 'Longitude must be a non-negative number';
        isValid = false;
    }

    if (property.numberOfBedrooms === '' || property.numberOfBedrooms < 0) {
        errors.numberOfBedrooms = 'Number of Bedrooms must be a non-negative number';
        isValid = false;
    }

    if (property.numberOfBathrooms === '' || property.numberOfBathrooms < 0) {
        errors.numberOfBathrooms = 'Number of Bathrooms must be a non-negative number';
        isValid = false;
    }

    if (!property.constructionYear || property.constructionYear.trim() === '') {
        errors.constructionYear = 'Construction Year is required';
        isValid = false;
    }

    if (!property.agentName || property.agentName.trim() === '') {
        errors.agentName = 'Agent Name is required';
        isValid = false;
    }

    if (!property.agentPhone || !enforce10DigitNumbersNoSpaces(property.agentPhone)) {
        errors.agentPhone = 'Agent Phone must be a valid 10-digit number without spaces';
        isValid = false;
    }

    if (!property.agentEmail || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(property.agentEmail)) {
        errors.agentEmail = 'Valid Agent Email is required';
        isValid = false;
    }

    return { isValid, errors };
};
