import React from 'react';
import { Modal, Button } from 'react-bootstrap'; // Assuming you are using React Bootstrap

const AuthModal = ({ show, handleClose, navigate }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Authentication Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>If you already have an account, please log in. If not, register first.</p>
        <div className="d-flex justify-content-between">
          <Button variant="primary" onClick={() => navigate('/login')}>
            Login
          </Button>
          <button className='btn btn-accent' onClick={() => navigate('/register')}>
            Register
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
