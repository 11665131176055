import React, { useState,useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import ApiService from '../../../config/api'; // Adjust the path as per your file structure
import axios from 'axios';
import { propertyObject } from '../../../models/property';
import { validatePropertyForm } from '../../../models/propertyValidation'; // Adjust the path as needed
import { useParams } from 'react-router-dom';

import { enforce10DigitNumbersNoSpaces } from '../../../config/utility';
const EditPropertyForm = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
    const toggleDrawer = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const [property, setProperty] = useState(propertyObject);
    const [errorMessages, setErrorMessages] = useState({});

    const [nearbyPlace, setNearbyPlace] = useState('');
    const [amenity, setAmenity] = useState('');
    const [imagePreviews, setImagePreviews] = useState([]);
    const [serverImages, setServerImages] = useState([]); // Images fetched from the API
    const [newImages, setNewImages] = useState([]); // New images to be uploaded
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            console.log("Editing mode, Property ID:", id); // For debugging

            fetchPropertyDetails(id); // Fetch the property details if editing
        }
    }, [id]);

    const fetchPropertyDetails = async (id) => {
        try {
            const successResponse = await ApiService.get(`admin/property/${id}`);
            const { meta: { property } } = successResponse.data;
            setProperty(property);
            setImagePreviews(property.images); // Use URLs directly for previews
            setServerImages(property.images); // Use the URLs from the server

        } catch (error) {
            console.error('Error fetching property details:', error);
            // Handle error, e.g., show an error message
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setProperty({
            ...property,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        console.log(files)
        const newImages = [...property.images, ...files];
        const newPreviews = newImages.map(file => URL.createObjectURL(file));
        setProperty({ ...property, images: newImages });
        setImagePreviews(newPreviews);
    };

    const handleRemoveServerImage = (image) => {
        setServerImages(serverImages.filter((img) => img !== image));
    };

    const handleRemoveNewImage = (index) => {
        const updatedNewImages = [...newImages];
        const updatedImagePreviews = [...imagePreviews];
        updatedNewImages.splice(index, 1);
        updatedImagePreviews.splice(index, 1);
        setNewImages(updatedNewImages);
        setImagePreviews(updatedImagePreviews);
    };


    const handle360ImageChange = (e) => {
        const file = e.target.files[0];
        console.log(file, "360")
        setProperty({
            ...property,
            images360: URL.createObjectURL(file)
        });
    };

    const handle3DModelChange = (e) => {
        const file = e.target.files[0];
        console.log(file, "3d")

        setProperty({
            ...property,
            threeDModels: URL.createObjectURL(file)
        });
    };

    //method for creating the property
    // const handleSubmit = async (e) => {
    //     try {
    //         e.preventDefault();

    //         const { isValid, errors } = validatePropertyForm(property);
    //         if (!isValid) {
    //             setErrorMessages(errors);
    //             return;
    //         }

    //         const formdata = new FormData();
    //         for (const key in property) {
    //             if (Array.isArray(property[key])) {
    //                 property[key].forEach((value, index) => {
    //                     if (key == 'images') {
    //                         formdata.append(`${key}`, value);
    //                     } else {
    //                         formdata.append(`${key}[${index}]`, value);

    //                     }
    //                 });
    //             } else {
    //                 formdata.append(key, property[key]);
    //             }
    //         }

    //         // To log the FormData keys and values for verification
    //         for (let pair of formdata.entries()) {
    //             console.log(pair[0] + ', ' + pair[1]);
    //         }

    //         const response = await ApiService.put(`update-property/${id}`, formdata);
    //         console.log('Images uploaded successfully:', response.data);
    //         // Handle success, e.g., show a success message or redirect
    //     } catch (error) {
    //         console.error('Error uploading images:', error);
    //         // Handle error, e.g., show an error message
    //     }
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const { isValid, errors } = validatePropertyForm(property);
    //     if (!isValid) {
    //         setErrorMessages(errors);
    //         return;
    //     }

    //     const formData = new FormData();

    //     Object.keys(property).forEach((key) => {
    //         if (Array.isArray(property[key])) {
    //             property[key].forEach((value) => formData.append(key, value));
    //         } else {
    //             formData.append(key, property[key]);
    //         }
    //     });

    //     newImages.forEach((file) => {
    //         formData.append('images', file);
    //     });

    //     formData.append('serverImages', JSON.stringify(serverImages)); // Pass remaining server images

    //     try {
    //         const response = await ApiService.put(`update-property/${id}`, formData);
    //         console.log('Images uploaded successfully:', response.data);
    //     } catch (error) {
    //         console.error('Error uploading images:', error);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const { isValid, errors } = validatePropertyForm(property);
        if (!isValid) {
            setErrorMessages(errors);
            return;
        }
    
        const formData = new FormData();
    
        // Add other form fields except for images
        Object.keys(property).forEach((key) => {
            if (key !== 'images' && key !== 'serverImages') {
                formData.append(key, property[key]);
            }
        });
    
        // Initialize serverImages and images arrays if not already
        const serverImages = Array.isArray(property.serverImages) ? property.serverImages : [];
        const newImages = Array.isArray(property.images) ? property.images : [];
    
        // Merge server images and new images into a single array
        const mergedImages = [...serverImages, ...newImages];
    
        // Loop through mergedImages and append them to the formData
        mergedImages.forEach((image) => {
            if (typeof image === 'string') {
                // Handle existing image URLs
                formData.append('existingImages', image); // Adjust the key if needed by your backend
            } else if (image instanceof File) {
                // Handle new image files
                formData.append('images', image); // This should append the file
            }
        });
    
        try {
            const response = await ApiService.put(`update-property/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Images updated successfully:', response.data);
        } catch (error) {
            console.error('Error updating images:', error);
        }
    };
    
    

    return (
        <Box
            display="grid"
            gridTemplateColumns={sidebarOpen ? '200px 1fr' : '60px 1fr'}
            sx={{
                transition: 'grid-template-columns 0.3s ease-in-out',
                overflow: 'hidden',
            }}
        >
            <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} setOpen={setSidebarOpen} />

            <div className='property-cont'>
                

                <div className="card rounded  card-add-property g-2">
                <div className="px-3 py-3" style={{ borderBottom: "1px solid var(--bs-secondary)" }}>
                            <h5 className='mb-0 p-2 '>Edit Property</h5>
                        </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input placeholder='Enter Title' className={`customInput form-control ${errorMessages.title ? 'is-invalid' : ''}`} type="text" id="title" name="title" value={property.title} onChange={handleChange} />
                                    {errorMessages.title && <p className="error-text">{errorMessages.title}</p>}

                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <textarea className="form-control" id="description" name="description" rows="3" value={property.description} onChange={handleChange}  ></textarea>
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="discountPrice" className="form-label">Discount Price</label>
                                    <input className="customInput form-control" type="number" id="discountPrice" name="discountPrice" value={property.discountPrice} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="price" className="form-label">Price</label>
                                    <input className="customInput form-control" type="number" id="price" name="price" value={property.price} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="floor" className="form-label">Floor</label>
                                    <input className="customInput form-control" type="number" id="floor" name="floor" value={property.floor} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="area" className="form-label">Area</label>
                                    <input className="customInput form-control" type="number" id="area" name="area" value={property.area} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="city" className="form-label">City</label>
                                    <input className="customInput form-control" type="text" id="city" name="city" value={property.city} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="state" className="form-label">State</label>
                                    <input className="customInput form-control" type="text" id="state" name="state" value={property.state} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="country" className="form-label">Country</label>
                                    <input className="customInput form-control" type="text" id="country" name="country" value={property.country} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="pincode" className="form-label">Pincode</label>
                                    <input className="customInput form-control" type="text" id="pincode" name="pincode" value={property.pincode} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="latitude" className="form-label">Latitude</label>
                                    <input className="customInput form-control" type="number" id="latitude" name="latitude" value={property.latitude} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="longitude" className="form-label">Longitude</label>
                                    <input className="customInput form-control" type="number" id="longitude" name="longitude" value={property.longitude} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="numberOfBedrooms" className="form-label">Number of Bedrooms</label>
                                    <input className="customInput form-control" type="number" id="numberOfBedrooms" name="numberOfBedrooms" value={property.numberOfBedrooms} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="numberOfBathrooms" className="form-label">Number of Bathrooms</label>
                                    <input className="customInput form-control" type="number" id="numberOfBathrooms" name="numberOfBathrooms" value={property.numberOfBathrooms} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="constructionYear" className="form-label">Construction Year</label>
                                    <input className="customInput form-control" type="text" id="constructionYear" name="constructionYear" value={property.constructionYear} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="agentName" className="form-label">Agent Name</label>
                                    <input className="customInput form-control" type="text" id="agentName" name="agentName" value={property.agentName} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="agentPhone" className="form-label">Agent Phone</label>
                                    <input className="customInput form-control" type="number" id="agentPhone" name="agentPhone" value={property.agentPhone} onChange={handleChange} onInput={enforce10DigitNumbersNoSpaces} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="agentEmail" className="form-label">Agent Email</label>
                                    <input className="customInput form-control" type="email" id="agentEmail" name="agentEmail" value={property.agentEmail} onChange={handleChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="amenity" className="form-label">Add Amenity</label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={amenity}
                                            onChange={(e) => setAmenity(e.target.value)}
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={() => {
                                                setProperty((prevProperty) => ({
                                                    ...prevProperty,
                                                    amenities: [...prevProperty.amenities, amenity]
                                                }));
                                                setAmenity('');
                                            }}
                                        >
                                            Add
                                        </button>
                                    </div>
                                    <ul>
                                        {property.amenities.map((amenity, index) => (
                                            <li key={index}>{amenity}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="nearbyPlace" className="form-label">Add Nearby Place</label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={nearbyPlace}
                                            onChange={(e) => setNearbyPlace(e.target.value)}
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={() => {
                                                setProperty((prevProperty) => ({
                                                    ...prevProperty,
                                                    nearbyPlaces: [...prevProperty.nearbyPlaces, nearbyPlace]
                                                }));
                                                setNearbyPlace('');
                                            }}
                                        >
                                            Add
                                        </button>
                                    </div>
                                    <ul>
                                        {property.nearbyPlaces.map((place, index) => (
                                            <li key={index}>{place}</li>
                                        ))}
                                    </ul>
                                </div>


                                <div className="col-md-4 px-4 mb-3">
                                    <label className="form-label">Upload Images</label>
                                    <input className="form-control" type="file" multiple onChange={handleFileChange} />
                                    <div className="mt-2">
                                        {serverImages.map((image, index) => (
                                            <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px', marginBottom: '10px' }}>
                                                <img src={image} alt={`Preview ${index}`} style={{ maxWidth: '100px' }} />
                                                <button
                                                    type="button"
                                                    style={{ position: 'absolute', top: 0, right: 0 }}
                                                    onClick={() => handleRemoveServerImage(image)}
                                                >
                                                    &#10005;
                                                </button>
                                            </div>
                                        ))}

                                        {imagePreviews.map((preview, index) => (
                                            <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px', marginBottom: '10px' }}>
                                                <img src={preview} alt={`Preview ${index}`} style={{ maxWidth: '100px' }} />
                                                <button
                                                    type="button"
                                                    style={{ position: 'absolute', top: 0, right: 0 }}
                                                    onClick={() => handleRemoveNewImage(index)}
                                                >
                                                    &#10005;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>


                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="images360" className="form-label">Upload 360 Image</label>
                                    <input className="form-control" type="file" id="images360" name="images360" onChange={handle360ImageChange} />
                                </div>
                                <div className="col-md-4 px-4 mb-3">
                                    <label htmlFor="threeDModels" className="form-label">Upload 3D Model</label>
                                    <input className="form-control" type="file" id="threeDModels" name="threeDModels" onChange={handle3DModelChange} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-accent">
                                Submit
                            </button>
                            
                        </form>
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default EditPropertyForm;
